import React, { useState } from 'react';

function DebugPage() {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    setLoading(true);
    try {

      const h5_pay_url_test = 'https://dory-safe-inherently.ngrok-free.app/api/payments/h5_pay';

      const h5_Pay_url = 'https://tomatopayment.com/api/payments/h5_pay';


      const response = await fetch(h5_Pay_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          merchantId: '2',
          orderAmount: parseInt(amount, 10),
          description: 'test description0827',
        }),
      });

      const result = await response.json();

      if (result.code === 200) {
        window.location.href = result.data.mweb_url;
      } else {
        alert('Payment failed: ' + result.msg);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Payment request failed.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>h5_pay Demo</h2>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount"
        style={styles.input}
      />
      <button onClick={handlePayment} disabled={loading} style={styles.button}>
        {loading ? 'Processing...' : 'Confirm Payment'}
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    padding: '20px',
    boxSizing: 'border-box',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  input: {
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    fontSize: '16px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    maxWidth: '400px',
    padding: '15px',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
};

export default DebugPage;
